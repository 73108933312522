@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.link-animation:after {
  display: block;
  content: "";
  border-bottom: solid 3px black;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.link-animation:hover:after {
  transform: scaleX(1);
}
