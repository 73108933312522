.head {
    font-family: 'AirAmericana'; 
    font-size: 3.5rem; /* Default size */
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center; /* Centers text horizontally */
    width: 100%; /* Ensures full width to center text properly */
}

.sponsors-section {
    background-color: #E3EFF2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow: hidden; /* Prevents overflow */
  }
  
  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%; /* Ensures row takes full width */
    margin-bottom: 0px; /* Adds space between rows */
    margin-top: 40px;
    gap: 20px; /* Adds space between tiles within the same row */
  }
  
  .tile {
    flex-basis: calc(100% / 3); /* Starts with three tiles per row */
    flex-grow: 1;
    max-width: 450px;
    height: 450px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds shadow for better visibility */
  }

  .tile img {
    width: 100%; 
    height: 100%; 
    object-fit: contain; 
    padding: 10px;
}
  
  @media (max-width: 1024px) {
    .tile {
      flex-basis: calc(100% / 2); /* Two tiles per row on tablet sizes */
    }
  }
  
  @media (max-width: 600px) {
    .tile {
      flex-basis: 100%; /* One tile per row on mobile sizes */
    }
  }