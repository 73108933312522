@font-face {
    font-family: 'AirAmericana';
    src: url('assets/AirAmericanaDEMO-Light.ttf') format('truetype');
}

.head {
    font-family: 'AirAmericana';
}

.select {
    background-color: #9bdfef;
}

.txt {
    color: #5395F4;
    border-radius: 0.75rem;
    border-width: 2px;
    border-color: #5395F4;
}

.nonselect {
    color: #e3eff2;
}

.all {
    color: rgba(26, 62, 48, 1);
}