/* styles.css */
@media (max-width: 820px) {
    .top-left-image,
    .bottom-right-image {
      display: none !important;
    }
  }
  
  #about-content {
    max-width: 100%;
  }
  
  @media (min-width: 821px) {
    #about-content {
      max-width: 58%;
    }
  }
  @media (max-width: 890px) {
    #about {
      height: 100vh;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  
    #about-content {
      max-width: 80%;
      margin: 0 auto;
    }
  }
  