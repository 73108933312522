/* General styles */
.head {
  font-family:'AirAmericana';
  color: #063f2f;
}

.exec-section {
  background-color: #E3EFF2;
  width: 100vw;
  height: auto;
  padding-bottom: 20px;
}

/* Grid styles */
.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Tile styles */
.tile-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Adjusted margin for the first two tiles */
.tile-wrapper:first-of-type .tile-container,
.tile-wrapper:nth-of-type(2) .tile-container {
  margin: 10px 60px;
}

/* General tile styles */
.exec-tile {
  background-color: white;
  padding: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 170px;
  height: 170px;
}

.member-photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tile-description {
  text-align: center;
  margin-top: 10px;
}

.member-name {
  font-size: 1.3em;
  font-weight: bold;
  color: #063f2f;
}

.member-role {
  font-size: 1.1em;
  color: #666;
  color: #063f2f;
}

/* Responsive styles */
@media (max-width: 767px) {
  .tile-wrapper {
      flex: 0 0 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tile-wrapper {
      flex: 0 0 50%;
  }
}

@media (min-width: 1025px) {
  .tile-wrapper:first-of-type, .tile-wrapper:nth-of-type(2) {
      flex: 0 0 auto;
  }

  .tile-wrapper:nth-of-type(n+3) {
      flex: 0 0 20%;
  }
}
