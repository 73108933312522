@font-face {
  font-family: 'AirAmericana';
  src: url('assets/AirAmericanaDEMO-Light.ttf') format('truetype');
}
.heading {
  font-family: 'AirAmericana';
}

.faq-section {
  padding-bottom: 10px;
  background-color: #E3EFF2;
  overflow: hidden;
}

.email {
  padding-top: 10px;
}

.myboxcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.question {
  padding-bottom: 10px;
}

.front {
  color: #1A3E30;
}

.img-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.selectedbox {
  background-color: #9bdfef;
}

.unselectedbox {
  background-color: #E3EFF2;
}

.faq-card {
  display: block;
  overflow: hidden;
  perspective: 1000px;
  border-radius: 10px;
  border-color: #B7E1EC;
  border-width: 2px;
  transition: transform 0.2s ease-in-out;
  /* margin: 10px 0; */
  width: 90%;
  /* max-width: 600px; */
}

.front, .back {
  backface-visibility: hidden;
}

.arrow-flipped {
  transform: rotate(180deg);
}

.faqa {
  padding-bottom: 10px;
}
